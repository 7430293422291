import isEqual from 'lodash/isEqual';
import type MarkdownIt from 'markdown-it';

import type { IDMap } from '../../../convert-prosemirror-to-markdown/pm-markdown/serializer';
import type { FeatureToggles } from '../../../feature-keys';
import { markdownPlusAttributeStatus } from '../../index';

export default function (
	md: MarkdownIt,
	idMap: IDMap,
	featureToggles: FeatureToggles,
	htmlConversionCounts: {
		htmlConvertedToNodes: number;
		htmlConvertedToFallbackNodes: number;
	},
	markdownPlusAttributeStatus: markdownPlusAttributeStatus[],
) {
	const rule = md.inline.ruler.before('text', 'custom', function (state, silent) {
		const start = state.pos;
		const origSrc = state.src;

		let match: RegExpMatchArray | null;
		if (featureToggles.markdownPlusAttributeTest) {
			match = state.src.slice(start).match(
				/**
				 * Matches the following:
				 * <custom data-type="smartlink" data-id="123">content</custom>
				 * <custom data-id="123">content</custom>
				 * <custom data-type="emoji">content</custom>
				 * <custom>content</custom>
				 * <custom data-type="status" data-id="id-0" data-attrs='{"text":"test","color":"neutral"}'>test</custom>
				 */
				// Ignored via go/ees005
				// eslint-disable-next-line require-unicode-regexp
				/<custom(?:\s+data-type="(?<dataType>[^"]*)")?(?:\s+data-id="(?<dataId>[^"]*)")?(?:\s+data-attrs="(?<dataAttrs>\{.*?\})")?\s*>(?<dataContent>.*?)<\/custom>/,
			);
		} else {
			match = state.src.slice(start).match(
				/**
				 * Matches the following:
				 * <custom data-type="smartlink" data-id="123">content</custom>
				 * <custom data-id="123">content</custom>
				 * <custom data-type="emoji">content</custom>
				 * <custom>content</custom>
				 */
				// Ignored via go/ees005
				// eslint-disable-next-line require-unicode-regexp
				/<custom(\sdata-(type|id)="(?<dataType>.*?)")?(\sdata-(type|id)="(?<dataId>.*?)")?>(?<dataContent>.*?)<\/custom>/,
			);
		}

		if (!match) {
			return false;
		}

		const tagLength = match[0].length;
		const beforeTag = state.src.slice(start, state.src.indexOf(match[0]));
		const dataType = match.groups?.dataType;
		const dataId = match.groups?.dataId;
		const dataAttrs = match.groups?.dataAttrs;
		const dataContent = match.groups?.dataContent;

		const afterTag = state.src.slice(start + beforeTag.length + tagLength);

		function mapCustomDataTypeToNodeType(customDataType?: string) {
			switch (customDataType) {
				case 'smartlink':
					return 'inlineCard';
				case 'date':
				case 'emoji':
				case 'status':
				case 'mention':
				case 'placeholder':
					return customDataType;
				// Just move below case alongside mention above to remove markdownPlusExtensions FF.
				case 'inlineExtension':
					return featureToggles.markdownPlusExtensions ? 'inlineExtension' : false;
				case 'mediaInline':
					return featureToggles.markdownPlusAddComplexNodes ? 'mediaInline' : false;
				default:
					return false;
			}
		}
		const customDataType = mapCustomDataTypeToNodeType(dataType);

		if (!silent) {
			// tokenize beforeTag
			if (beforeTag) {
				state.src = beforeTag;
				// @ts-ignore
				state.md.inline.tokenize(state, beforeTag);
			}
			if (dataId && idMap?.[dataId]?.attributes && customDataType) {
				htmlConversionCounts.htmlConvertedToNodes++;
				const customDataToken = state.push(customDataType, '', 0);
				// @ts-ignore
				customDataToken.pmAttrs = idMap[dataId].attributes;

				if (featureToggles.markdownPlusAttributeTest && dataAttrs) {
					try {
						if (isEqual(idMap[dataId].attributes, JSON.parse(dataAttrs))) {
							markdownPlusAttributeStatus.push([customDataType, true]);
						} else {
							// We need to check this because when you type it calls this function multiple times and the first time its called the current text is not the same
							if (idMap[dataId].attributes.text === JSON.parse(dataAttrs).text) {
								markdownPlusAttributeStatus.push([customDataType, false]);
							}
						}
					} catch (e) {
						markdownPlusAttributeStatus.push([customDataType, false]);
					}
				}
			} else {
				htmlConversionCounts.htmlConvertedToFallbackNodes++;

				const textToken = state.push('text', '', 0);

				// If there is no data content we want to hide the custom tags as the AI has hallucinated something
				if (!dataContent) {
					textToken.content = '';
				} else {
					textToken.content = dataContent || match[0];
				}
			}

			if (afterTag) {
				state.src = afterTag;
				state.pos = 0;
				// @ts-ignore
				state.md.inline.tokenize(state, afterTag);
			}
		}
		state.src = origSrc;
		state.pos = start + state.src.length;
		return true;
	});
	return rule;
}
